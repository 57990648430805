<template>
  <div>
    <b-row class="name-label-row-class" no-gutters>
      <b-col>
        {{ label }}
      </b-col>
    </b-row>
    <b-row class="mt-1 name-date-row-class" no-gutters>
      <b-col>
        {{ date }}
      </b-col>
    </b-row>
  </div>
</template>
<script>
  export default {
    props: {
      date: {
        type: String,
        default: this
      },
      label: {
        type: String,
        default: this
      },
    }
}
</script>
<style scoped>
.name-label-row-class {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #363565;
  opacity: 1;
}

.name-date-row-class {
  text-align: left;
  font: normal normal normal 13px Avenir;
  letter-spacing: 0px;
  color: #5C5E6A;
  opacity: 1;
}
</style>